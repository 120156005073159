<template>
  <svg viewBox="0 0 113 93" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M69.6413 14.4487H98.0669C100.051 14.4485 102.013 14.8618 103.828 15.6622C105.644 16.4626 107.272 17.6326 108.61 19.0975C109.948 20.5624 110.966 22.29 111.599 24.1703C112.232 26.0505 112.466 28.042 112.287 30.0178L107.74 79.9876C107.417 83.5348 105.781 86.8335 103.151 89.2359C100.521 91.6384 97.0887 92.9712 93.5268 92.9726H19.6501C16.0883 92.9712 12.6556 91.6384 10.026 89.2359C7.39632 86.8335 5.75967 83.5348 5.43732 79.9876L0.890077 30.0178C0.585878 26.7111 1.4497 23.4024 3.33146 20.6664L3.05305 14.4487C3.05305 10.6622 4.55724 7.03076 7.23471 4.35329C9.91218 1.67582 13.5436 0.171631 17.3301 0.171631H43.5428C47.329 0.172439 50.9599 1.67716 53.6367 4.35481L59.5474 10.2655C62.2243 12.9432 65.8551 14.4479 69.6413 14.4487ZM10.2344 15.3053C11.7621 14.7485 13.4039 14.4487 15.1172 14.4487H53.6367L48.5898 9.40176C47.2513 8.06293 45.4359 7.31057 43.5428 7.31016H17.3301C15.4601 7.30983 13.6647 8.04327 12.3298 9.35281C10.9949 10.6624 10.2271 12.4434 10.1916 14.3131L10.2344 15.3053Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconFolder'
}
</script>
